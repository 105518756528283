import React, { useState, useRef, useEffect } from 'react';
import '../CSS/form.css';
import Checkbox from './Checkbox';
import emailjs from '@emailjs/browser';
import { FormSelect } from 'react-bootstrap';
import { clear } from '@testing-library/user-event/dist/clear';

const Form = () => {
  const formRef = useRef();
  const initialValues = {
    panna_mloda: '',
    pan_mlody: '',
    numer_kontaktowy: '',
    email: '',
    data: '',
    miejsce_slubu: '',
    miejsce_wesela: '',
    goscie: '',
    budzet: '',
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [success, setSuccess] = useState('');
  const [selectedOption, setSelectedOption] = useState('kwiaciarnia');
  const [consent, setConsent] = useState(false);
  const [consentError, setConsentError] = useState('');
  const [checkboxError, setCheckboxError] = useState('');
  const [bukiet, setBukiet] = useState(false);
  const [butonierka, setButonierka] = useState(false);
  const [bransoletka, setBransoletka] = useState(false);
  const [wianek, setWianek] = useState(false);
  const [dekoracjaSali, setDekoracjaSali] = useState(false);
  const [dekoracjaKosciola, setDekoracjaKosciola] = useState(false);
  const handleBukietChange = (e) => {
    setBukiet(e.target.checked);
  };
  const handleButonierkaChange = (e) => {
    setButonierka(e.target.checked);
  };
  const handleBransoletkaChange = (e) => {
    setBransoletka(e.target.checked);
  };
  const handleWianekChange = (e) => {
    setWianek(e.target.checked);
  };
  const handleDekoracjaSaliChange = (e) => {
    setDekoracjaSali(e.target.checked);
  };
  const handleDekoracjaKosciolaChange = (e) => {
    setDekoracjaKosciola(e.target.checked);
  };

  const handleOptionChange = (changeEvent) => {
    setSelectedOption(changeEvent.target.value);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validate = (values) => {
    const errors = {};
    if (!values.panna_mloda) {
      errors.panna_mloda = 'Proszę wprowadzić imię Panny młodej';
    }
    if (!values.pan_mlody) {
      errors.pan_mlody = 'Proszę wprowadzić imię Pana młodego';
    }
    if (!values.numer_kontaktowy) {
      errors.numer_kontaktowy = 'Proszę wprowadzić numer kontaktowy';
    }
    if (!values.email) {
      errors.email = 'Proszę wprowadzić adres mailowy';
    }
    if (!values.data) {
      errors.data = 'Proszę wprowadzić datę ślubu';
    }
    if (!values.miejsce_slubu) {
      errors.miejsce_slubu = 'Proszę wprowadzić miejsce ślubu';
    }
    if (!values.miejsce_wesela) {
      errors.miejsce_wesela = 'Proszę wprowadzić miejsce wesela';
    }
    if (!values.goscie) {
      errors.goscie = 'Proszę wprowadzić przewidywaną liczbę gości';
    }
    if (!values.budzet) {
      errors.budzet = 'Proszę wprowadzić planowany budżet na dekorację';
    }

    return errors;
  };

  const clearEntries = () => {
    setFormValues({
      panna_mloda: '',
      pan_mlody: '',
      numer_kontaktowy: '',
      email: '',
      data: '',
      miejsce_slubu: '',
      miejsce_wesela: '',
      goscie: '',
      budzet: '',
      pinterest: '',
    });
    setConsent(false);
    setBukiet(false);
    setBukiet(false);
    setButonierka(false);
    setBransoletka(false);
    setWianek(false);
    setDekoracjaKosciola(false);
    setDekoracjaSali(false);
    setTimeout(() => {
      setSuccess('');
    }, 6000);
  };

  const checkboxesCheck = () => {
    if (
      !bukiet &&
      !butonierka &&
      !bransoletka &&
      !wianek &&
      !dekoracjaKosciola &&
      !dekoracjaSali
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validate(formValues);
    setFormErrors(errors);
    const checking = await checkboxesCheck();
    if (!consent) {
      setConsentError('Proszę wyraźić zgodę na przetwarzanie danych osobowych');
    } else {
      setConsentError('');
    }

    if (
      !bukiet &&
      !butonierka &&
      !bransoletka &&
      !wianek &&
      !dekoracjaKosciola &&
      !dekoracjaSali
    ) {
      setCheckboxError('Proszę zaznaczyć co najmniej jedną opcję');
    } else {
      setCheckboxError('');
    }

    if (
      Object.keys(errors).length === 0 &&
      consent === true &&
      checking === true
    ) {
      emailjs
        .sendForm(
          'service_zf0k7jr',
          'template_syh3foo',
          formRef.current,
          '2aRB3MFBnjATkK5V0'
        )
        // .then(
        //   () => {
        //     console.log('SUCCESS!');
        //   },
        //   (error) => {
        //     console.log('FAILED...', error.text);
        //   }
        // );
        .then((result) => {
          // console.log(result.text);
          setSuccess(
            'Formularz został wysłany pomyślnie. Wkrótce się odezwiemy!'
          );
        })
        .catch((error) => error)
        .finally(clearEntries());
    }
  };

  useEffect(() => {
    // console.log(formErrors);
    if (Object.keys(formErrors).length === 0) {
      // console.log(formValues);
    }
  }, [formErrors]);

  return (
    <div className="app__form" id="form">
      <div className="app__form-heading-container" data-aos="fade-down">
        <h1 className="app__form-heading">Formularz ślubny</h1>
        <div className="app__form-heading_underscore"></div>
      </div>
      <div className="app__form-container">
        <form
          onSubmit={handleSubmit}
          className="app__form-content"
          ref={formRef}
        >
          <div className="app__form-first_container" data-aos="fade-right">
            <div className="app__form-element">
              <label className="app__form-label" htmlFor="woman-name">
                Imię i nazwisko Panny Młodej
              </label>
              <input
                value={formValues.panna_mloda}
                name="panna_mloda"
                autoComplete="off"
                onChange={handleChange}
                type="text"
                id="woman-name"
                className="primary-input"
                style={
                  formErrors.panna_mloda && {
                    border: '1px solid red',
                    borderRadius: '3px',
                  }
                }
              />
              <p className="error">{formErrors.panna_mloda}</p>
            </div>
            <div className="app__form-element">
              <label className="app__form-label" htmlFor="man-name">
                Imię i nazwisko Pana Młodego
              </label>
              <input
                value={formValues.pan_mlody}
                name="pan_mlody"
                onChange={handleChange}
                autoComplete="off"
                type="text"
                id="man-name"
                className="primary-input"
                style={
                  formErrors.pan_mlody && {
                    border: '1px solid red',
                    borderRadius: '3px',
                  }
                }
              />
              <p className="error">{formErrors.pan_mlody}</p>
            </div>
            <div className="app__form-element">
              <label className="app__form-label" htmlFor="number">
                Telefon osoby do kontaktu
              </label>
              <input
                value={formValues.numer_kontaktowy}
                name="numer_kontaktowy"
                onChange={handleChange}
                type="number"
                autoComplete="off"
                id="number"
                className="primary-input"
                style={
                  formErrors.numer_kontaktowy && {
                    border: '1px solid red',
                    borderRadius: '3px',
                  }
                }
              />
              <p className="error">{formErrors.numer_kontaktowy}</p>
            </div>
            <div className="app__form-element">
              <label className="app__form-label" htmlFor="email">
                Adres e-mail
              </label>
              <input
                value={formValues.email}
                name="email"
                onChange={handleChange}
                type="email"
                id="email"
                className="primary-input"
                autoComplete="off"
                style={
                  formErrors.email && {
                    border: '1px solid red',
                    borderRadius: '3px',
                  }
                }
              />
              <p className="error">{formErrors.email}</p>
            </div>
            <div className="app__form-element">
              <label className="app__form-label" htmlFor="date">
                Data ślubu
              </label>
              <input
                value={formValues.data}
                name="data"
                onChange={handleChange}
                type="date"
                id="date"
                autoComplete="off"
                className="primary-input"
                style={
                  formErrors.data && {
                    border: '1px solid red',
                    borderRadius: '3px',
                  }
                }
              />
              <p className="error">{formErrors.data}</p>
            </div>
            <div className="app__form-element">
              <label className="app__form-label" htmlFor="place">
                Miejsce ślubu
              </label>
              <input
                value={formValues.miejsce_slubu}
                name="miejsce_slubu"
                onChange={handleChange}
                type="text"
                id="place"
                autoComplete="off"
                className="primary-input"
                style={
                  formErrors.miejsce_slubu && {
                    border: '1px solid red',
                    borderRadius: '3px',
                  }
                }
              />
              <p className="error">{formErrors.miejsce_slubu}</p>
            </div>
            <div className="app__form-element">
              <label className="app__form-label" htmlFor="place_two">
                Miejsce wesela
              </label>
              <input
                value={formValues.miejsce_wesela}
                name="miejsce_wesela"
                onChange={handleChange}
                type="text"
                autoComplete="off"
                id="place_two"
                className="primary-input"
                style={
                  formErrors.miejsce_wesela && {
                    border: '1px solid red',
                    borderRadius: '3px',
                  }
                }
              />
              <p className="error">{formErrors.miejsce_wesela}</p>
            </div>
            <div className="app__form-element">
              <label className="app__form-label" htmlFor="guests">
                Przewidywana liczba gości
              </label>
              <input
                value={formValues.goscie}
                name="goscie"
                onChange={handleChange}
                type="number"
                id="guests"
                autoComplete="off"
                className="primary-input"
                style={
                  formErrors.goscie && {
                    border: '1px solid red',
                    borderRadius: '3px',
                  }
                }
              />
              <p className="error">{formErrors.goscie}</p>
            </div>
            <div className="app__form-element budget">
              <label className="app__form-label" htmlFor="budget">
                Planowany budżet na dekorację
              </label>
              <div className="app__form-budget_inner">
                <input
                  value={formValues.budzet}
                  name="budzet"
                  onChange={handleChange}
                  autoComplete="off"
                  type="number"
                  id="budget"
                  className="primary-input"
                  style={
                    formErrors.budzet && {
                      border: '1px solid red',
                      borderRadius: '3px',
                    }
                  }
                />

                <p className="currency">zł</p>
              </div>
              <p className="error">{formErrors.budzet}</p>
            </div>
          </div>
          <div className="app__form-second_container" data-aos="fade-left">
            <Checkbox
              handleBukietChange={handleBukietChange}
              bukiet={bukiet}
              checkboxError={checkboxError}
              butonierka={butonierka}
              handleButonierkaChange={handleButonierkaChange}
              setButonierka={setButonierka}
              setBransoletka={setBransoletka}
              handleBransoletkaChange={handleBransoletkaChange}
              bransoletka={bransoletka}
              wianek={wianek}
              setWianek={setWianek}
              handleWianekChange={handleWianekChange}
              dekoracjaKosciola={dekoracjaKosciola}
              setDekoracjaKosciola={setDekoracjaKosciola}
              handleDekoracjaKosciolaChange={handleDekoracjaKosciolaChange}
              dekoracjaSali={dekoracjaSali}
              setDekoracjaSali={setDekoracjaSali}
              handleDekoracjaSaliChange={handleDekoracjaSaliChange}
            />
            <div className="app__form-element file-input_container">
              <p className="file-container_heading">Załączniki</p>
              <p className="file-container_text">
                Korzystając z poniższego linku dodajcie kilka zdjęć, na których
                są dekoracje, które wpadły Wam w oko i zainspirowały do Waszej
                dekoracji wesela lub wklejcie link do tablicy na Pintereście.
                <label htmlFor="" className="pinterest-container">
                  Dodaj link do tablicy
                  <input
                    type="url"
                    value={formValues.pinterest}
                    onChange={handleChange}
                    autocomplete="off"
                    className="primary-input"
                  />
                </label>
              </p>
              <div className="file-inner_container">
                <button type="button" className="photos-btn">
                  <a
                    className="photo-link"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSdNoRSaWIhE_8LZqk2U5zUZFzq2jMahZQmiCfVeczR_ZJ26mg/viewform"
                    target="_blank"
                  >
                    Dodaj zdjęcia
                  </a>
                </button>
              </div>
            </div>
            <div className="app__form-element radio">
              <p className="radio-heading">Interesuje nas konsultacja</p>
              <label className="form__choices-radio">
                <input
                  value="kwiaciarnia"
                  name="spotkanie"
                  onChange={handleOptionChange}
                  type="radio"
                  id="shop"
                  checked={selectedOption === 'kwiaciarnia'}
                />
                <span className="radio-span"></span>
                <p className="radio-choices__text">W kwiaciarni</p>
              </label>
              <label className="form__choices-radio">
                <input
                  value="online"
                  name="spotkanie"
                  onChange={handleOptionChange}
                  type="radio"
                  id="shop"
                  checked={selectedOption === 'online'}
                />
                <span className="radio-span"></span>
                <p className="radio-choices__text">
                  Online poprzez Google meet
                </p>
              </label>
            </div>
            <div className="form__consent">
              <label className="form__checkbox">
                <input
                  type="checkbox"
                  onChange={(e) => setConsent(e.target.checked)}
                  onClick={() => setConsent(!consent)}
                  value={consent}
                  name="consent"
                  checked={consent}
                />
                <span className="checkbox"></span>
                <p className="consent__text">
                  Wyrażam zgodę na przetwarzanie moich danych osobowych dla
                  potrzeb niezbędnych do realizacji zapytania ofertowego
                  złożonego w formularzu. (zgodnie z Ustawą z dnia 29.08.1997
                  roku o Ochronie Danych Osobowych; tekst jednolity: Dz. U. 2016
                  r. poz. 922).
                </p>
              </label>
              <p className="error">{consentError}</p>
            </div>
            <div className="app__form-btn_container">
              <p className="success">{success}</p>
              <button className="app__form-btn" type="submit">
                Wyślij
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
