import logo from '../assets/logo.png';
import hero from '../assets/hero.jpg';
import icon from '../assets/icon.png';
import decoration from '../assets/decoration.png';
import decoration_extra from '../assets/decoration-extra.png';
import about from '../assets/about.jpg';
import carousel_one from '../assets/carousel_one.jpg';
import carousel_two from '../assets/carousel_two.jpg';
import carousel_three from '../assets/carousel_three.jpg';
import carousel_four from '../assets/carousel_four.png';
import carousel_five from '../assets/carousel_five.png';
import carousel_six from '../assets/carousel_six.png';
import carousel_seven from '../assets/carousel_seven.png';
import carousel_eight from '../assets/carousel_eight.jpg';
import carousel_nine from '../assets/carousel_nine.png';
import carousel_ten from '../assets/carousel_ten.jpg';
import carousel_eleven from '../assets/carousel_eleven.jpg';
import carousel_twelve from '../assets/carousel_twelve.jpg';
import carousel_thirteen from '../assets/carousel_thirteen.jpg';
import carousel_fourteen from '../assets/carousel_fourteen.png';
import wedding_one from '../assets/wedding_one.jpg';
import wedding_two from '../assets/wedding_two.jpg';
import wedding_three from '../assets/wedding_three.jpg';
import wedding_carousel_three from '../assets/wedding_carousel_three.png';
import wedding_carousel_two from '../assets/wedding_carousel_two.png';
import wedding_carousel_one from '../assets/wedding_carousel_one.jpg';
import landscape_1 from '../assets/landscape_1.png';
import landscape_2 from '../assets/landscape_2.jpg';
import landscape3 from '../assets/landscape3.jpg';
import landscape4 from '../assets/landscape4.jpg';
import landscape5 from '../assets/landscape5.jpg';
import landscape6 from '../assets/landscape6.jpg';
import landscape7 from '../assets/landscape7.jpg';
import landscape8 from '../assets/landscape8.jpg';
import landscape9 from '../assets/landscape9.jpg';
import portrait_one from '../assets/portrait_one.jpg';
import portrait_two from '../assets/portrait_two.png';
import portrait_three from '../assets/portrait_three.jpg';
import portrait_four from '../assets/portrait_four.png';
import portrait_5 from '../assets/portrait_5.png';
import portrait_6 from '../assets/portrait_6.png';

export default {
  logo,
  hero,
  icon,
  decoration,
  decoration_extra,
  about,
  carousel_one,
  carousel_two,
  carousel_three,
  carousel_four,
  carousel_five,
  carousel_six,
  carousel_seven,
  carousel_eight,
  carousel_nine,
  carousel_ten,
  carousel_eleven,
  carousel_twelve,
  carousel_thirteen,
  carousel_fourteen,
  wedding_one,
  wedding_two,
  wedding_three,
  wedding_carousel_one,
  wedding_carousel_two,
  wedding_carousel_three,
  landscape_1,
  landscape_2,
  landscape3,
  landscape4,
  landscape5,
  landscape6,
  landscape7,
  landscape8,
  landscape9,
  portrait_one,
  portrait_two,
  portrait_three,
  portrait_four,
  portrait_6,
  portrait_5,
};
